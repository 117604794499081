import styled from '@emotion/styled';
import { sizes, Tab, TabList, TabPanel, Tabs } from '@smartsheet/lodestar-core';
import { AsyncStatus, HttpStatusCodes } from '../../../common/enums';
import { DetailsPanelTabType } from '../../../common/enums/DetailsPanelTabType.enum';
import { Attachment, CellObjectValue, Discussion, FormFieldInterface, IPaginatedResult, RowData, SmartsheetUser } from '../../../common/interfaces';
import { useCallback, useEffect, useRef, useState } from 'react';
import * as React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { AutomationIds } from '../../../common/enums/AutomationElements.enum';
import { useLanguageElements } from '../../../language-elements/withLanguageElementsHOC';
import { SubmittedForm } from '../SubmittedForm';
import { Actions as DetailsPanelActions } from './Actions';
import { Actions as AppActions } from '../../../containers/App/Actions';
import { Actions as ViewActions } from '../../../containers/View/Actions';
import { Actions as ImageActions } from '../../../store/images/Actions';
import AttachmentComponent from './Attachment/Attachment';
import { CellImages } from './CellImage/CellImages';
import Comment from './Comment/Comment';
import DetailsData from './DetailsData/DetailsData';
import { LoadingData } from './LoadingData';
import { mapSubmittedFormToFormFieldInterfaces } from './MapSubmittedFormToFormFieldInterfaces';
import { RowRemovedFromView } from './RowRemovedFromView';
import { detailsPanelShowModalSelector, latestCurrentRowUpsertSelector, selectedRowIdSelector, selectStatusForCurrentRow } from './Selectors';
import { currentRowSheetIdSelector, viewDataSelector } from '../Selectors';
import viewClient from '../../../http-clients/View.client';
import { isNotUndefined } from '../../../common/utils';
import { isAxiosErrorWithResponse } from '../../../common/utils/isAxiosErrorWithResponse';
import { loggingClient } from '../../../http-clients/Logging.client';
import { getInitialTab, mapContactColumns } from './DetailsUtils';

export interface DetailsProps {
    detailsPanelDescription?: string;
    initialTab?: DetailsPanelTabType;
    isNewSubmission: boolean;
    onChangeIsDirty: (isDirty: boolean) => void;
    onCloseDetailsPanel: () => void;
    selectedRowId?: string;
    smartsheetUsers: IPaginatedResult<SmartsheetUser>;
    viewId: string;
    width: number;
}

const FILENAME = 'Details.tsx';

export const Details = (props: DetailsProps) => {
    const languageElements = useLanguageElements();
    const [activeTab, setActiveTab] = useState(getInitialTab(props.isNewSubmission, props.initialTab));
    const [isDetailsDataComponentDirty, setIsDetailsDataComponentDirty] = useState(false);
    const [isCommentComponentDirty, setIsCommentComponentDirty] = useState(false);

    const detailsPanelShowModal = useSelector(detailsPanelShowModalSelector);
    const dispatch = useDispatch();
    const selectedRowId = useSelector(selectedRowIdSelector);
    const rowIdRef = useRef(selectedRowId);

    const detailsDataSaveStatus = useSelector(selectStatusForCurrentRow);
    const viewData = useSelector(viewDataSelector);
    const sheetId = useSelector(currentRowSheetIdSelector);

    const { viewId, isNewSubmission, initialTab, onChangeIsDirty, onCloseDetailsPanel } = props;

    /*
     * The commented callback functions below are placeholders for now…
     * until we move data-fetching into the child components!
     */

    // @ts-ignore
    const [attachmentTotal, setAttachmentTotal] = useState(0);
    // const updateAttachmentTotal = useCallback((list: Attachment[]) => {
    //     setAttachmentTotal(Array.isArray(list.length) ? list.length : 0)
    // }, []);

    // @ts-ignore
    const [discussionTotal, setDiscussionTotal] = useState(0);
    // const updateDiscussionTotal = useCallback((list: Discussion[]) => {
    //     setDiscussionTotal(Array.isArray(list.length) ? list.length : 0)
    // }, []);

    const handleTabClick = (clickedTab: DetailsPanelTabType) => {
        // Don't do anything if the user clicked on the current tab.
        if (activeTab === clickedTab) {
            return;
        }

        if (activeTab === DetailsPanelTabType.DATA && detailsDataSaveStatus === AsyncStatus.NOT_STARTED) {
            return;
        }

        // If the user is moving away from the details tab, and they have unsaved changes, prompt them to first save.
        // Or if the user is moving away from the comments tab, and they have unsaved changes, ask them to first save.
        // The Attachments panel doesn't get dirty. Uploading attachments is a single step.
        const detailsDataNeedsSaving = activeTab === DetailsPanelTabType.DATA && isDetailsDataComponentDirty;
        const commentsNeedSaving = activeTab === DetailsPanelTabType.COMMENTS && isCommentComponentDirty;
        if (detailsDataNeedsSaving || commentsNeedSaving) {
            dispatch(DetailsPanelActions.showModalDetailsPanel());
            return;
        }

        setActiveTab(clickedTab);
    };

    const handleDetailsDataChangeIsDirty = useCallback(
        (isDirty: boolean) => {
            setIsDetailsDataComponentDirty(isDirty);

            onChangeIsDirty(isDirty || isCommentComponentDirty);
        },
        [isCommentComponentDirty, onChangeIsDirty]
    );

    const handleCommentChangeIsDirty = (isDirty: boolean) => {
        setIsCommentComponentDirty(isDirty);

        onChangeIsDirty(isDetailsDataComponentDirty || isDirty);
    };

    const resetIsDirty = useCallback(() => {
        setIsDetailsDataComponentDirty(false);
        setIsCommentComponentDirty(false);
        onChangeIsDirty(false);
    }, [onChangeIsDirty]);

    const handleCommentAndAttachmentSaveComplete = (): void => {
        resetIsDirty();
        getRowData(selectedRowId!);
    };

    const rowUpsert = useSelector(latestCurrentRowUpsertSelector);

    const [attachments, setAttachments] = useState<Attachment[] | undefined>([]);
    const [discussions, setDiscussions] = useState<Discussion[] | undefined>([]);
    const [form, setForm] = useState<FormFieldInterface[] | undefined>(undefined);
    const [detailsDataSubmittedForm, setDetailsDataSubmittedForm] = useState<SubmittedForm | undefined>();
    const [isLoading, setIsLoading] = useState(true);

    const handleDetailsDataSave = useCallback(
        (submittedForm: SubmittedForm, cellImages: CellImages) => {
            // This scrollTo readjusts display on mobile devices
            window.scrollTo(0, 0);

            const originalRowData: RowData = {
                form: form != null ? form : [],
                attachments,
                discussions,
            };

            if (selectedRowId == null) {
                let mappedFormFields = mapSubmittedFormToFormFieldInterfaces(originalRowData.form, submittedForm, cellImages);

                // If no data is being saved in the new row, and we have cell images to attach,
                // then use the alt text for the cell images for the text values of the fields.
                // This will allow the new row to be saved, since otherwise reports make blank rows
                // inaccessible to the view, and then attaching the images to the cell in the new row would fail.
                if (mappedFormFields.length < 1 && cellImages.altText.size > 0) {
                    const submittedFormForAltText: SubmittedForm = {};
                    (cellImages.altText as Map<number, CellObjectValue>).forEach(
                        (altText, columnId) => (submittedFormForAltText[columnId] = altText)
                    );
                    const emptyCellImages: CellImages = { attachedForUpload: new Map(), altText: new Map(), scheduledForRemoval: new Map() };
                    mappedFormFields = mapSubmittedFormToFormFieldInterfaces(originalRowData.form, submittedFormForAltText, emptyCellImages);
                }

                // In the scenario where the initial insert request fails, the props.selectRowId will be populated with a temp ID.
                // As a consequence, on the retry of the insert a check is also performed to see if the row upsert has the isNewSubmission prop
                // set to true.
                dispatch(DetailsPanelActions.insertViewRow({ viewId, submittedForm: mappedFormFields, cellImages }));
            } else {
                const mappedFormFields = mapSubmittedFormToFormFieldInterfaces(originalRowData.form, submittedForm, cellImages);
                dispatch(
                    DetailsPanelActions.updateViewRow({
                        viewId,
                        rowId: selectedRowId,
                        originalForm: originalRowData.form,
                        submittedForm: mappedFormFields,
                        cellImages,
                    })
                );
            }

            setDetailsDataSubmittedForm(submittedForm);
            // This stops a modal informing a user of the dirty state from appearing when a user tries to click on another row
            resetIsDirty();
        },
        [attachments, discussions, dispatch, form, viewId, resetIsDirty, selectedRowId]
    );

    useEffect(() => {
        switch (detailsDataSaveStatus) {
            case undefined:
                // no save in progress here, initial state
                if (form !== undefined) {
                    setDetailsDataSubmittedForm(mapContactColumns(form, props.smartsheetUsers.data));
                }
            case AsyncStatus.NOT_STARTED:
            case AsyncStatus.IN_PROGRESS:
                // if the save was successful and the form is returned as undefined, we will render <RowRemovedFromView> instead of the form.
                // we assign `form = undefined` so we know this inside <Details>
                if (rowUpsert?.updatedForm === undefined) {
                    setDetailsDataSubmittedForm(undefined);
                } else {
                    setDetailsDataSubmittedForm(mapContactColumns(rowUpsert.updatedForm, props.smartsheetUsers.data));
                }
                // clear dirty state to allow further edits at this point
                handleDetailsDataChangeIsDirty(false);
                break;
            case AsyncStatus.ERROR:
                if (form !== undefined) {
                    setForm(mapContactColumns(form, props.smartsheetUsers.data));
                }
                break;
            case AsyncStatus.DONE:
            default:
                break;
        }
    }, [detailsDataSaveStatus, form, handleDetailsDataChangeIsDirty, props.smartsheetUsers.data, rowUpsert]);

    useEffect(() => {
        if (form === undefined) {
            return;
        }

        const formFieldsWithCellImages = form.filter((formField) => formField.image);
        if (formFieldsWithCellImages.length) {
            const images = formFieldsWithCellImages.map((formField) => formField.image).filter(isNotUndefined);
            dispatch(ImageActions.fetchImageUrls(images));
        }
    }, [dispatch, form]);

    const getRowData = useCallback(
        async (rowId: string) => {
            const response = await viewClient.getViewRow(viewId, rowId, sheetId);
            setAttachments(response.data.attachments);
            setDiscussions(response.data.discussions);
            setForm(mapContactColumns(response.data.form, props.smartsheetUsers.data));
            setIsLoading(false);
            return;
        },
        [props.smartsheetUsers.data, sheetId, viewId]
    );

    const handleGetRowData = () => {
        getRowData(selectedRowId!);
    };

    const getNewRowData = useCallback(async () => {
        const response = await viewClient.getViewRowForm(viewId);
        setAttachments([]);
        setDiscussions([]);
        setForm(response.data.form);
        setIsLoading(false);
    }, [viewId]);

    useEffect(() => {
        // trigger this at least once per mount
        setActiveTab(getInitialTab(isNewSubmission, initialTab));
    }, [isNewSubmission, initialTab]);

    useEffect(() => {
        if (isDetailsDataComponentDirty) {
            return;
        }

        setIsLoading(true);
        window.scrollTo(0, 0);
        try {
            if (selectedRowId) {
                getRowData(selectedRowId);
            } else {
                // if selectedRowId is '', null, or undefined,
                // this means the user is creating a new row
                getNewRowData();
            }

            // Check for the issue when the async request is in progress, but the user has clicked on a different row. In that case,
            // Only update the state if the selectedRowId matches the props.selectedRowId.
        } catch (error) {
            if (isAxiosErrorWithResponse(error) && error.response!.status === HttpStatusCodes.NOT_FOUND) {
                // If a 404 (Not Found) error is returned, then the row no longer exists in the view and
                // the details panel is closed
                loggingClient.logInfo({
                    file: FILENAME,
                    message: `Getting row data failed, row not in the view`,
                    viewId,
                    rowId: selectedRowId,
                });
                // const removeGridRow = dispatch(ViewActions.removeGridRow);
                dispatch(ViewActions.removeGridRow)(viewId, selectedRowId!);
                onCloseDetailsPanel();
            } else {
                loggingClient.logError(FILENAME, 'getRowData', error, {
                    viewId,
                    rowId: selectedRowId,
                });

                setIsLoading(false);
            }
            dispatch(AppActions.setAppStageError(error));
        }
    }, [
        dispatch,
        viewId,
        initialTab,
        isNewSubmission,
        onCloseDetailsPanel,
        selectedRowId,
        sheetId,
        rowIdRef,
        isDetailsDataComponentDirty,
        getNewRowData,
        getRowData,
    ]);

    if (viewData.status !== AsyncStatus.PARTIAL && viewData.status !== AsyncStatus.DONE) {
        return null;
    }

    const {
        displayComments,
        addComments: canAddComments,
        displayAttachments,
        addAttachments: canAddAttachments,
        description: detailsPanelDescription,
    } = viewData.data;

    return (
        <DetailsWrapper data-client-id={AutomationIds.DETAILS_WRAP}>
            {isLoading && <LoadingData />}
            <TitleContainer>
                <TitleStyled>{languageElements.DETAIL_PANEL_DETAILS}</TitleStyled>
                {detailsPanelDescription && <DescriptionStyled>{detailsPanelDescription}</DescriptionStyled>}
            </TitleContainer>
            <Tabs selectedId={activeTab} onChange={handleTabClick} onlyRenderActiveTabPanelChildren={true}>
                <TabListStyled aria-label={languageElements.DETAIL_PANEL_LABEL}>
                    <Tab id={DetailsPanelTabType.DATA} data-client-id={AutomationIds.DETAILS_TAB_DATA} data-testid={AutomationIds.DETAILS_TAB_DATA}>
                        {languageElements.DETAIL_PANEL_DATA}
                    </Tab>
                    {!isNewSubmission && displayAttachments && (
                        <Tab
                            id={DetailsPanelTabType.ATTACHMENTS}
                            data-client-id={AutomationIds.DETAILS_TAB_ATTACHMENTS}
                            data-testid={AutomationIds.DETAILS_TAB_ATTACHMENTS}
                        >
                            {languageElements.DETAIL_PANEL_ATTACHMENTS} ({attachmentTotal})
                        </Tab>
                    )}
                    {!isNewSubmission && displayComments && (
                        <Tab
                            id={DetailsPanelTabType.COMMENTS}
                            data-client-id={AutomationIds.DETAILS_TAB_COMMENTS}
                            data-testid={AutomationIds.DETAILS_TAB_COMMENTS}
                        >
                            {languageElements.DETAIL_PANEL_COMMENTS} ({discussionTotal})
                        </Tab>
                    )}
                </TabListStyled>
                {!isLoading && form === undefined && <RowRemovedFromView />}
                <TabPanelStyled>
                    {form !== undefined && (
                        <DetailsData
                            form={form} // does it make sense to pass empty array when form is undefined?
                            smartsheetUsers={props.smartsheetUsers}
                            viewId={viewId}
                            rowId={selectedRowId}
                            onChangeIsDirty={handleDetailsDataChangeIsDirty}
                            showModal={detailsPanelShowModal}
                            onSave={handleDetailsDataSave}
                            saveStatus={detailsDataSaveStatus}
                            submittedForm={detailsDataSubmittedForm}
                            isNewSubmission={isNewSubmission}
                            getRowData={handleGetRowData}
                        />
                    )}
                </TabPanelStyled>
                {!isNewSubmission && displayAttachments && (
                    <TabPanelStyled>
                        <AttachmentComponent
                            addAttachments={canAddAttachments ?? false}
                            attachments={attachments}
                            viewId={viewId}
                            rowId={parseInt(selectedRowId!, 10)}
                            width={props.width}
                            onAttach={handleCommentAndAttachmentSaveComplete}
                        />
                    </TabPanelStyled>
                )}
                {!isNewSubmission && displayComments && (
                    <TabPanelStyled>
                        <Comment
                            viewId={viewId}
                            rowId={parseInt(selectedRowId!, 10)}
                            discussions={discussions}
                            width={props.width}
                            addComments={canAddComments ?? false}
                            onNewComment={handleCommentAndAttachmentSaveComplete}
                            onChangeIsDirty={handleCommentChangeIsDirty}
                            showModal={detailsPanelShowModal}
                        />
                    </TabPanelStyled>
                )}
            </Tabs>
        </DetailsWrapper>
    );
};

const DetailsWrapper = styled.div`
    position: relative;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    flex: 1;
    width: 100%;
`;

const TitleContainer = styled.div`
    padding: 15px;
    box-sizing: border-box;
`;

const TitleStyled = styled.h3`
    font-size: 18px;
    font-weight: 800;
    margin: 0 0 ${sizes.xSmall}px 0;
    height: 24px;
`;

const DescriptionStyled = styled.p`
    margin: 0;
    font-size: 13px;
    font-weight: 400;
    line-height: 13px;
`;

const TabListStyled = styled(TabList)`
    padding: 0 ${sizes.medium}px;
`;

const TabPanelStyled = styled(TabPanel)`
    display: flex;
    overflow: hidden;
    flex: 1;
    width: 100%;
    height: 100%;
`;
