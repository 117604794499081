import styled from '@emotion/styled';
import { colors, sizes } from '@smartsheet/lodestar-core';
import { AxiosError, AxiosResponse } from 'axios';
import * as React from 'react';
import { SyntheticEvent } from 'react';
import { ConnectedProps, connect } from 'react-redux';
import { RouteComponentProps, withRouter } from 'react-router';
import { Link } from 'react-router-dom';
import iconClose from '../../assets/images/close-button.svg';
import settingsIcon from '../../assets/images/icons/settings-blue.svg';
import { PaginatedUserResult } from '../../common/classes';
import { Filter } from '../../common/classes/Filter/Filter';
import { AccessLevel, AsyncStatus, ColumnType, ErrorType } from '../../common/enums';
import { AutomationIds } from '../../common/enums/AutomationElements.enum';
import { UserActions } from '../../common/enums/UserActions.enum';
import { UserViewSettings } from '../../common/enums/UserViewSettings.enum';
import * as dv from '../../common/interfaces';
import { Cell, Column, IFilter, IFilterUI, Row, SmartsheetUser, ViewData } from '../../common/interfaces';
import { ActionType, UserAnalyticsAction } from '../../common/metrics/UserAnalyticsAction';
import { UserAnalyticsGlobalContext } from '../../common/metrics/UserAnalyticsGlobalContext';
import { isMultiContactObjectValue, isMultiPicklistObjectValue } from '../../common/utils';
import { ColumnWidthRepository } from '../../common/utils/ColumnWidthRepository';
import { getDefaultDateFnsFormatForLocale } from '../../common/utils/DatesFns';
import { dynamicSort } from '../../common/utils/DynamicSort';
import { isDateObjectValue } from '../../common/utils/IsDateObjectValue';
import { formattedDateString } from '../../common/utils/MomentDates';
import { OptionRepository } from '../../common/utils/OptionRepository';
import { SymbolLookup, ViewSource } from '../../common/utils/ViewSource';
import { ViewSourceFactory } from '../../common/utils/ViewSourceFactory';
import { BaseComponent } from '../../components/Base';
import { ErrorInfo } from '../../components/EmptyState/ErrorInfo.interface';
import { Grid } from '../../components/Grid';
import { RowData } from '../../components/Grid/Grid.interface';
import withSetAppActionInProgress, { WithSetAppActionInProgressProps } from '../../components/hoc/WithSetAppActionInProgress';
import withSetAppError, { WithSetAppErrorProps } from '../../components/hoc/WithSetAppError';
import { MultiSelectItem } from '../../components/MultiSelect/MultiSelect.interface';
import RSidePanel from '../../components/RSidePanel';
import Spinner, { Color, Size } from '../../components/Spinner';
import * as AppActions from '../../containers/App/Actions';
import usersClient from '../../http-clients/Users.client';
import viewClient from '../../http-clients/View.client';
import { LanguageElementsProp, withLanguageElementsHOC } from '../../language-elements/withLanguageElementsHOC';
import { StoreState } from '../../store';
import * as ImageActions from '../../store/images/Actions';
import * as AppSelectors from '../App/Selectors';
import * as DetailsPanelSelectors from '../View/Details/Selectors';
import * as AuthSelectors from '../Auth/Selectors';
import * as HomeActions from '../Home/Actions';
import * as ViewActions from './Actions';
import './CellFormats.css';
import Description from './Description/index';
import * as DetailsPanelActions from './Details/Actions';
import { Details } from './Details/Details';
import DetailsContainer from './Details/DetailsContainer';
import { DismissErrors } from './DismissErrors/DismissErrors';
import Download from './Download';
import { applyFilter } from './Filter/ApplyFilter/ApplyFilter';
import { createAllFilterItemsMap } from './Filter/CreateAllFilterItemsMap';
import FilterContainer from './Filter/FilterContainer';
import * as ViewSelectors from './Selectors';
import ToggleFormats from './ToggleFormats/ToggleFormats';
import ToggleWrapText from './ToggleWrapText/ToggleWrapText';
import { createGridInformation } from './utils/CreateGridInformation';
import { VerticalRule } from './VerticalRule';
import './View.css';
import ViewHeader from './ViewHeader';
import { ViewStatus } from './ViewStatus/ViewStatus';

interface OwnProps {
    viewId: string;
}

type Props = RouteComponentProps<OwnProps> & PropsFromRedux & WithSetAppActionInProgressProps & WithSetAppErrorProps & LanguageElementsProp;

interface State {
    showDetailsPanel: boolean;
    showViewDescription: boolean;
    rowId?: number;
    isNewSubmission: boolean;
    viewName?: string;
    viewDescription?: string;
    viewOwner: undefined | dv.ViewOwner;
    currentUserAccessLevel: AccessLevel;
    userCanCreateNewItem: boolean;
    smartsheetUsers: dv.IPaginatedResult<SmartsheetUser>;
    viewSource: ViewSource | undefined;
    routeToViewDescription: boolean;
    wrapText: boolean;
    errorInfo?: ErrorInfo;
    filterModalOpen: boolean;
    filterDisabled: boolean;
    filters?: IFilterUI[];
    filterMenuOpen: boolean;
    isNewFilter: boolean;
    isDuplicateFilter: boolean;
    activeFilterId: string | undefined;
    editFilterId: string | undefined;
    showFormats: boolean;
    csvData?: object[];
    filteredRows: RowData[];
    isUsersLoadedWithFlag: boolean;
    isVisibleLoadingIndicator: boolean;
}

const containerWidth = 300;

/**
 * Responsible for loading grid table data and related general view information for title,
 * filters and group-by options
 */
export class ViewContainer extends BaseComponent<Props, State> {
    public state: State;
    private viewId: string;
    private isDetailsRowDirty: boolean = false;
    private loadViewStats = {
        inProgress: false,
        start: 0,
    };

    private gridRows: RowData[];
    private gridCellMap: Map<string, dv.Cell>; // key of gridCellMap is a string in the format rowId:columnId
    private columnCellMap: Map<number, Map<string, MultiSelectItem>>; // columnCellMap is of format Map<columnId, Map<displayValue, cellValue>>
    private allFilterItemsMap: Map<number, Map<string, MultiSelectItem>>;

    public constructor(props: Props) {
        super(props);
        this.viewId = props.match.params.viewId;
        this.state = this.getInitialState();
        this.handleClickHeaderOrFilterButton = this.handleClickHeaderOrFilterButton.bind(this);
    }

    public render(): React.ReactNode {
        const {
            viewSource,
            filters = [],
            filterDisabled,
            filterModalOpen,
            filterMenuOpen,
            activeFilterId,
            editFilterId,
            showDetailsPanel,
            viewName,
            currentUserAccessLevel,
            viewDescription,
            userCanCreateNewItem,
            isNewFilter,
            isDuplicateFilter,
            wrapText,
            showFormats,
            errorInfo,
            smartsheetUsers,
            showViewDescription,
            viewOwner,
            csvData,
            filteredRows,
        } = this.state;
        const activeFilterIndex = this.getFilterIndex(activeFilterId, filters);
        const editFilterIndex = this.getFilterIndex(editFilterId, filters);
        const dataLoadingMessage = this.props.languageElements.HOME_TABLE_DATA_LOADING_MESSAGE;
        return (
            <div className="grid-container">
                <div className="grid-view-header">
                    <ViewHeader viewName={viewName} viewId={this.viewId} onClickViewInfo={this.handleClickViewInfo} />

                    {this.state.isVisibleLoadingIndicator && this.props.viewData.status === AsyncStatus.PARTIAL ? (
                        <div>
                            <SpinnerWrapper>
                                <Spinner color={Color.BLUE} size={Size.XSMALL} />
                                <SpinnerLabel>{dataLoadingMessage}</SpinnerLabel>
                            </SpinnerWrapper>
                        </div>
                    ) : null}
                </div>

                <div className="grid-view-sub-header">
                    <div className="left-header-items">
                        {viewSource && (
                            <div className="left-header-items">
                                <FilterContainer
                                    fields={viewSource.viewData ? viewSource.viewData.columns : []}
                                    filters={filters}
                                    filterModalOpen={filterModalOpen}
                                    activeFilterIndex={activeFilterIndex}
                                    editFilterIndex={editFilterIndex}
                                    filterDisabled={filterDisabled}
                                    totalRows={viewSource.totalRows}
                                    filteredRowCount={filteredRows ? filteredRows.length : 0}
                                    onClickFilterIcon={this.handleClickFilterIcon}
                                    onClickFilterMenu={this.handleClickFilterMenu}
                                    onCancelFilter={this.handleCancelFilter}
                                    onDeleteFilter={(filterId: string) => this.handleDeleteFilter(filterId)}
                                    onApplyFilter={(filter: IFilterUI) => this.handleApplyFilter(filter)}
                                    onFilterModalClose={this.handleFilterModalClose}
                                    picklistSymbolImageMap={viewSource.picklistSymbolImageMap}
                                    allFilterItemsMap={this.allFilterItemsMap}
                                    filterMenuOpen={filterMenuOpen}
                                    onNewFilter={this.handleNewFilter}
                                    onFilterOff={this.handleFilterOff}
                                    onFilterSelect={(filter: IFilterUI) => this.handleFilterSelect(filter)}
                                    onFilterEdit={(filter: IFilterUI) => this.handleFilterEdit(filter)}
                                    onFilterDuplicate={(filter: IFilterUI) => this.handleFilterDuplicate(filter)}
                                    isNewFilter={isNewFilter}
                                    isDuplicateFilter={isDuplicateFilter}
                                    currentUserAccessLevel={currentUserAccessLevel}
                                    isLoadingData={this.props.viewData.status !== AsyncStatus.DONE}
                                    isVisibleLoadingIndicator={this.state.isVisibleLoadingIndicator}
                                    onClickFilterButton={() => this.handleClickHeaderOrFilterButton()}
                                />
                                <VerticalRule />
                                <ToggleFormats onClick={this.handleShowFormatsSelect} showFormats={showFormats} />
                                <VerticalRule />
                                <ToggleWrapText onClick={this.handleWrapTextSelect} wrapText={wrapText} />
                                {viewSource!.viewConfig.canDownload && viewSource.totalRows > 0 && (
                                    <>
                                        <VerticalRule />
                                        <Download
                                            filename={viewName}
                                            csvData={csvData ?? []}
                                            onClick={this.handleClickCsvExport}
                                            isLoadingData={this.props.viewData.status !== AsyncStatus.DONE}
                                            isVisibleLoadingIndicator={this.state.isVisibleLoadingIndicator}
                                        />
                                    </>
                                )}
                                <ViewStatus />
                                <DismissErrors />
                            </div>
                        )}
                    </div>

                    <div className="right-header-items">
                        {!this.props.inIframe &&
                            this.props.isUserLicensed &&
                            (currentUserAccessLevel === AccessLevel.OWNER || currentUserAccessLevel === AccessLevel.ADMIN) && (
                                <div className="settings" data-client-id={AutomationIds.VIEW_SETTINGS}>
                                    <Link to={`/views/${this.viewId}/admin/basic`} className={'link'}>
                                        <img src={settingsIcon} alt="settings icon" />
                                    </Link>
                                    <div className="tooltip">
                                        <div>{this.props.languageElements.VIEW_TABLE_SETTINGS}</div>
                                    </div>
                                </div>
                            )}
                        {userCanCreateNewItem && viewSource && (
                            <button className="btn btn-primary" data-client-id={AutomationIds.VIEW_NEW} onClick={this.handleClickNewButton}>
                                {this.props.languageElements.VIEW_TABLE_NEW}
                            </button>
                        )}
                    </div>
                </div>
                {viewSource && (
                    <div className="grid-view-all">
                        <div className="grid-view-body">
                            <Grid
                                onRowSelect={(rowInfo: RowData) => this.handleRowSelect(rowInfo)}
                                closeDetailsPanel={() => this.handleCloseDetailsPanel()}
                                onShouldGridActionProceed={this.handleShouldGridActionProceed}
                                selectedFilterIndex={activeFilterIndex}
                                viewSource={viewSource}
                                viewId={this.viewId}
                                accessLevel={currentUserAccessLevel}
                                wrapText={wrapText}
                                errorInfo={errorInfo}
                                filterDisabled={filterDisabled}
                                filter={this.getActiveFilter(activeFilterId, filters)}
                                onClickFilterButton={this.handleClickGridHeaderFilter}
                                inIframe={this.props.inIframe}
                                dataRows={filteredRows || []}
                                totalRows={viewSource.viewData ? viewSource.viewData.totalRowCount : 0}
                                onEscape={this.handleEscape}
                                showFormats={showFormats}
                                isBulletinDisplayed={this.props.isBulletinDisplayed}
                                // If details panel is open, don't alter focus
                                disableSetFocus={showDetailsPanel}
                                isLoadingData={this.props.viewData.status === AsyncStatus.PARTIAL}
                                isVisibleLoadingIndicator={this.state.isVisibleLoadingIndicator}
                                onClickHeaderButton={() => this.handleClickHeaderOrFilterButton()}
                            />
                        </div>
                        <RSidePanel
                            controlId={AutomationIds.VIEW_SIDE_PANEL}
                            inIframe={this.props.inIframe}
                            sidePanelOpened={showDetailsPanel}
                            onClick={this.handleCloseDetailsPanel}
                        >
                            <button
                                data-client-id={AutomationIds.VIEW_CLOSE}
                                className={'close-button'}
                                onClick={() => this.handleCloseDetailsPanel()}
                            >
                                <img src={iconClose} alt={'icon-close'} className={'icon-close'} />
                            </button>

                            {this.props.isAttachmentFeatureEnabled && (
                                <Details
                                    initialTab={viewSource.viewConfig?.detailsPanelInitialTab}
                                    isNewSubmission={this.state.isNewSubmission}
                                    key={this.props.selectedRowId}
                                    onChangeIsDirty={this.handleDetailsIsDirtyChange}
                                    onCloseDetailsPanel={this.handleCloseDetailsPanel}
                                    selectedRowId={this.props.selectedRowId}
                                    smartsheetUsers={smartsheetUsers}
                                    viewId={this.viewId}
                                    width={containerWidth}
                                />
                            )}
                            {!this.props.isAttachmentFeatureEnabled && (
                                <DetailsContainer
                                    viewId={this.viewId}
                                    width={containerWidth}
                                    displayComments={viewSource.viewConfig?.displayComments || false}
                                    addComments={viewSource.viewConfig?.canAddComments || false}
                                    displayAttachments={viewSource.viewConfig?.displayAttachments || false}
                                    addAttachments={viewSource.viewConfig?.canAddAttachments || false}
                                    onChangeIsDirty={this.handleDetailsIsDirtyChange}
                                    smartsheetUsers={smartsheetUsers}
                                    onCloseDetailsPanel={this.handleCloseDetailsPanel}
                                    detailsPanelDescription={viewSource.viewConfig?.detailsPanelDescription}
                                    detailsPanelInitialTab={viewSource.viewConfig?.detailsPanelInitialTab}
                                />
                            )}
                        </RSidePanel>
                        <RSidePanel
                            controlId={AutomationIds.VIEW_SIDE_PANEL}
                            inIframe={this.props.inIframe}
                            sidePanelOpened={showViewDescription}
                            onClick={this.handleCloseDetailsPanel}
                        >
                            <button
                                data-client-id={AutomationIds.VIEW_CLOSE}
                                className={'close-button'}
                                onClick={() => this.handleCloseDetailsPanel()}
                            >
                                <img src={iconClose} alt={'icon-close'} className={'icon-close'} />
                            </button>
                            <Description
                                viewDescription={viewDescription}
                                viewOwner={viewOwner!}
                                viewId={this.viewId!}
                                isLicensedUser={this.props.isUserLicensed}
                                currentUserAccessLevel={currentUserAccessLevel}
                                dataClientId={AutomationIds.DETAILS_TAB_DESCRIPTION}
                            />
                        </RSidePanel>
                    </div>
                )}
            </div>
        );
    }

    public componentDidMount(): void {
        this.initializeView();
    }

    public componentDidUpdate(prevProps: Props, prevState: State): void {
        const viewIdChanged = this.props.match.params.viewId !== prevProps.match.params.viewId;
        const viewDataChanged = this.props.viewData !== prevProps.viewData;
        const viewConfigChanged = this.props.viewConfig !== prevProps.viewConfig;

        if (this.state.isUsersLoadedWithFlag === false && this.props.disableContactPickerOnDemand !== undefined) {
            this.setSmartsheetUsers();
            this.setState({ isUsersLoadedWithFlag: true });
        }

        if (viewIdChanged) {
            this.initializeView();
        } else if (viewDataChanged || viewConfigChanged) {
            if (viewConfigChanged) {
                this.setViewInformation();
            }

            this.setGridAndFilterData();
        } else if (this.state.isNewSubmission) {
            this.setState({ isNewSubmission: false });
        }
    }

    public componentWillUnmount(): void {
        // Reset document title
        document.title = this.props.languageElements.APPLICATION_NAME;

        // Remove view details from user analytics
        UserAnalyticsGlobalContext.removeViewData();

        this.props.resetViewState();
        this.props.resetDetailsPanelState();
    }

    public handleDetailsIsDirtyChange = (isDirty: boolean): void => {
        this.isDetailsRowDirty = isDirty;
    };

    public handleCloseDetailsPanel = (): void => {
        // This scrollTo readjusts display on mobile devices
        window.scrollTo(0, 0);

        if (this.isDetailsRowDirty) {
            this.props.showModalDetailsPanel();
        } else {
            this.setState({
                showDetailsPanel: false,
                showViewDescription: false,
            });
        }
    };

    public handleShouldGridActionProceed = (): boolean => {
        if (this.isDetailsRowDirty) {
            this.props.showModalDetailsPanel();
            return false;
        }

        return true;
    };

    public handleRowSelect = (rowInfo: RowData): void => {
        if (!rowInfo.id) {
            return;
        }

        this.props.selectRow(rowInfo.id);

        this.setState({
            showDetailsPanel: true,
            showViewDescription: false,
        });
    };

    public handleClickViewInfo = (): void => {
        if (this.isDetailsRowDirty) {
            this.props.showModalDetailsPanel();
        } else {
            this.setState({
                rowId: undefined,
                showDetailsPanel: false,
                isNewSubmission: false,
                showViewDescription: true,
            });
        }
    };

    public handleClickNewButton = (): void => {
        if (this.isDetailsRowDirty) {
            this.props.showModalDetailsPanel();
        } else {
            this.props.unselectRow();

            this.setState({
                showDetailsPanel: true,
                isNewSubmission: true,
                showViewDescription: false,
            });
        }
    };

    // Toggles filter drop-down. Called when user clicks the filter button in header that contains text
    public handleClickFilterMenu = () => {
        this.setState((state) => ({
            filterMenuOpen: !state.filterMenuOpen,
        }));
    };

    /**
     * When there are no defined filters, clicking filter icon opens filter definition modal with empty filter
     * When defined filters exist, clicking filter icon updates grid by toggling filter on & off
     */
    public handleClickFilterIcon = () => {
        if (this.state.filters?.length) {
            this.toggleFilter(!this.state.filterDisabled);
        } else {
            this.setFilterModalOpen();
        }
    };

    public handleClickHeaderOrFilterButton() {
        if (this.props.viewData.status === AsyncStatus.PARTIAL) {
            this.setState({ isVisibleLoadingIndicator: true });
        } else {
            this.setState({ isVisibleLoadingIndicator: false });
        }
    }

    // Opens filter definition modal when user clicks on filter icon directly in a column header (opens modal with active filter info)
    public handleClickGridHeaderFilter = (key?: number) => {
        this.setFilterModalOpen(this.state.activeFilterId);
    };

    public handleFilterModalClose = () => {
        this.setState({ filterModalOpen: false });
    };

    /**
     * When the export button is clicked, we update state.csvData with the latest formatted viewData including filters applied.
     * This avoids unnecessary formatting of the csv data - which isn't needed unless the user downloads.
     * Note that this updates the props for the Download component BEFORE the CSVLink component is triggered - so the latest
     * data should be reflected in the download.
     */
    private handleClickCsvExport = (event: React.MouseEvent): void => {
        if (this.props.viewData.status === AsyncStatus.PARTIAL) {
            event.preventDefault();
            this.setState({ isVisibleLoadingIndicator: true });
            return;
        } else {
            this.setState({ isVisibleLoadingIndicator: false });
        }

        if (!this.state.viewSource || !this.state.viewSource.viewData) {
            return;
        }

        const viewData = this.state.viewSource.viewData;
        const columnMap = this.state.viewSource!.sheetColumnMap || this.state.viewSource!.reportColumnMap;
        const { filteredRows } = this.state;
        const filteredRowIds = new Set(filteredRows.map((row) => parseInt(row.id, 10)));

        // Submit user analytics event
        UserAnalyticsAction.addFromEvent(
            event,
            {
                viewId: viewData.viewId,
                viewSourceId: viewData.viewSourceId,
            },
            UserActions.CLICK_EXPORT_VIEW
        );

        const getDataRow = (row: Row): string[] => row.cells.map((cell) => this.getCellValue(cell, columnMap));
        const { columns, rows } = viewData;
        const headerRow = columns.map((column: Column) => column.title);

        const dataRows: string[][] = [];
        rows.forEach((row) => {
            if (filteredRowIds.has(row.id)) {
                dataRows.push(getDataRow(row));
            }
        });

        this.setState({ csvData: [headerRow, ...dataRows] });
    };

    private getFilteredRows(filter: IFilterUI | undefined, filterDisabled: boolean, viewSource?: ViewSource): RowData[] {
        if (!viewSource || !viewSource.viewData) {
            return [];
        }

        const columnMap = viewSource.getColumnMap();

        // Filter rows based on criteria and then map results to rowData[]
        return applyFilter(this.gridRows, this.gridCellMap, columnMap, !filterDisabled && filter ? filter : undefined);
    }

    private setSmartsheetUsers = async (): Promise<void> => {
        try {
            const users = await usersClient.getUsers(this.props.disableContactPickerOnDemand);
            this.setState({ smartsheetUsers: users });
        } catch (error) {
            const smartsheetUsers = new PaginatedUserResult();
            this.setState({ smartsheetUsers: smartsheetUsers.toWireModel() });
        }
    };

    private setViewInformation() {
        if (this.props.viewConfig.status === AsyncStatus.ERROR) {
            this.props.onSetAppStageError(this.props.viewConfig.error);
            return;
        }

        if (this.props.viewConfig.status !== AsyncStatus.PARTIAL && this.props.viewConfig.status !== AsyncStatus.DONE) {
            return;
        }

        const viewResponse: dv.ViewWithOwnerAndUserDetails = this.props.viewConfig.data;
        const currentUserAccessLevel: AccessLevel =
            viewResponse && viewResponse.currentUserDetails ? viewResponse.currentUserDetails.accessLevel : AccessLevel.NONE;
        const viewName = viewResponse.name;
        const viewDescription = viewResponse.description;
        const viewOwner = viewResponse.ownerDetails;
        const userCanCreateNewItem = Boolean(viewResponse.config!.intakeSheetId);
        const wrapText = OptionRepository.getOption(this.viewId, UserViewSettings.WRAP_TEXT);
        const showFormats = OptionRepository.getOption(this.viewId, UserViewSettings.SHOW_FORMATS, true);

        UserAnalyticsGlobalContext.addViewData(viewResponse);

        this.props.storeRecentView(this.viewId);

        // Add view name to document title (browser tab)
        document.title = viewName ? `${viewName} - ${this.props.languageElements.APPLICATION_NAME}` : this.props.languageElements.APPLICATION_NAME;

        this.setState({
            currentUserAccessLevel,
            userCanCreateNewItem,
            viewName,
            viewDescription,
            viewOwner,
            wrapText,
            showFormats,
        });
    }

    private setGridAndFilterData(): void {
        if (this.props.viewConfig.status !== AsyncStatus.DONE) {
            return;
        }

        let errorInfo;
        let viewData: dv.ViewData;
        if (this.props.viewData.status === AsyncStatus.ERROR) {
            errorInfo = this.getErrorInfoFromError(this.props.viewData.error);
            if (!errorInfo.errorHandled) {
                this.props.onSetAppStageError(this.props.viewData.error);
                return;
            }
        } else if (this.props.viewData.status === AsyncStatus.PARTIAL || this.props.viewData.status === AsyncStatus.DONE) {
            viewData = this.props.viewData.data;
        } else {
            return;
        }

        const viewResponse: dv.ViewWithOwnerAndUserDetails = this.props.viewConfig.data;
        const filters = this.state.filters || viewResponse.filters || [];
        let hasError = false;

        try {
            // Create a viewSource instance even if there is no viewData (to handle case when view exists but sheet filter does not)
            // Note that although viewResponse.config has filters[], we don't include it in the viewSource instance.
            const viewSource = this.recordLoadViewStatsForFunc('loadView.createViewSource', () =>
                ViewSourceFactory.create(viewResponse.config!, new ColumnWidthRepository(), this.props.languageElements, viewData)
            );

            // Format viewData, create gridCell map for applying filter, create columnValues map for filter value options
            this.recordLoadViewStatsForFunc('loadView.setGridInformation', () =>
                this.setGridInformation(
                    viewSource.sheetColumnMap,
                    viewSource.reportColumnMap,
                    viewSource.allColumnOptionsMap,
                    viewSource.picklistSymbolImageMap,
                    viewData ? viewData.rows : []
                )
            );

            // Get filter items by combining column options and add'l cell data
            this.recordLoadViewStatsForFunc('loadView.setFilterInformation', () =>
                this.setFilterInformation(viewSource.allColumnOptionsMap, this.columnCellMap)
            );

            const displayFilters = this.recordLoadViewStatsForFunc('loadView.getDisplayFilters', () =>
                this.getDisplayFilters(viewSource.viewData, filters, this.state.currentUserAccessLevel)
            );

            const activeFilterIndex = this.recordLoadViewStatsForFunc('loadView.getFilterIndex', () =>
                this.getFilterIndex(this.state.activeFilterId, displayFilters)
            );

            const filterDisabled = activeFilterIndex === -1 ? true : this.state.filterDisabled;

            // Apply filter
            const activeFilter = this.recordLoadViewStatsForFunc('loadView.getActiveFilter', () =>
                this.getActiveFilter(this.state.activeFilterId, displayFilters)
            );

            const filteredRows = this.recordLoadViewStatsForFunc('loadView.getFilteredRows', () =>
                this.getFilteredRows(activeFilter, filterDisabled, viewSource)
            );

            this.setState(
                {
                    errorInfo,
                    viewSource,
                    filterDisabled,
                    filters: displayFilters,
                    filteredRows,
                },
                () => this.props.onResetAppStage()
            );
        } catch (error) {
            hasError = true;
            this.props.onSetAppStageError(error);
        } finally {
            this.recordTotalLoadViewStats(hasError);
        }
    }

    private recordLoadViewStatsForFunc<T>(stat: string, func: () => T): T {
        if (!this.loadViewStats.inProgress) {
            return func();
        }

        return UserAnalyticsAction.recordSyncDuration(stat, () => func());
    }

    private recordTotalLoadViewStats(hasError = false): void {
        if (!this.loadViewStats.inProgress) {
            return;
        }

        const duration = Date.now() - this.loadViewStats.start;

        UserAnalyticsAction.add(ActionType.DURATION, 'loadView', {
            duration,
            hasError,
        });

        this.loadViewStats = {
            inProgress: false,
            start: 0,
        };
    }

    private getErrorInfoFromError(error: AxiosError<any, any>): ErrorInfo {
        let header: string | undefined;
        let message: string = this.props.languageElements.DEFAULT_MESSAGE;
        let errorHandled = false;

        if (error.response && error.response.data) {
            message = error.response.data.message;

            switch (error.response.data.errorCode) {
                case ErrorType.VIEW_SHEET_FILTER_DOES_NOT_EXIST_ADMIN:
                case ErrorType.VIEW_SHEET_FILTER_DOES_NOT_EXIST:
                    header = this.props.languageElements.SHEET_FILTER_INVALID;
                    errorHandled = true;
                    break;

                case ErrorType.VIEW_SOURCE_LOAD_ERROR:
                    header = this.props.languageElements.ERROR_HEADER;
                    errorHandled = true;
                    break;
            }
        }
        return { header, message, errorHandled };
    }

    private handleWrapTextSelect = (e: SyntheticEvent): void => {
        const wrapText = !this.state.wrapText;
        OptionRepository.setOption(this.viewId, UserViewSettings.WRAP_TEXT, wrapText);

        this.setState({
            wrapText,
        });
    };

    private handleShowFormatsSelect = (e: SyntheticEvent): void => {
        const showFormats = !this.state.showFormats;
        OptionRepository.setOption(this.viewId, UserViewSettings.SHOW_FORMATS, showFormats);

        this.setState({
            showFormats,
        });
    };

    private getInitialState = (): State => {
        const smartsheetUsers = new PaginatedUserResult();
        return {
            showDetailsPanel: false,
            showViewDescription: false,
            rowId: undefined,
            isNewSubmission: false,
            viewName: '',
            viewDescription: '',
            viewOwner: undefined,
            currentUserAccessLevel: AccessLevel.USER,
            userCanCreateNewItem: false,
            smartsheetUsers: smartsheetUsers.toWireModel(),
            viewSource: undefined,
            routeToViewDescription: false,
            wrapText: false,
            errorInfo: undefined,
            filterModalOpen: false,
            filterDisabled: true,
            filters: undefined,
            editFilterId: undefined,
            filterMenuOpen: false,
            isNewFilter: false,
            isDuplicateFilter: false,
            activeFilterId: undefined,
            showFormats: true,
            filteredRows: [],
            isUsersLoadedWithFlag: false,
            isVisibleLoadingIndicator: false,
        };
    };

    private handleDeleteFilter = async (filterId: string): Promise<boolean> => {
        let activeFilterId = this.state.activeFilterId;
        let filterDisabled = this.state.filterDisabled;
        let filteredRows = this.state.filteredRows;

        try {
            await viewClient.deleteFilter(this.viewId, filterId);

            const updatedFilters = this.state.filters ? this.state.filters.slice() : [];
            const deleteIndex = updatedFilters.findIndex((filter) => filter.id === filterId);
            updatedFilters.splice(deleteIndex, 1);

            // If the active filter was deleted, set activeFilterId to undefined and filterDisabled
            // to true so that grid will show all data (when a filter is deleted from drop-down, it's not necessarily the active filter)
            if (filterId === this.state.activeFilterId) {
                activeFilterId = undefined;
                filterDisabled = true;
                filteredRows = this.getFilteredRows(undefined, true, this.state.viewSource);
            }

            this.setState({
                activeFilterId,
                editFilterId: undefined,
                filterModalOpen: false,
                filterMenuOpen: false,
                isNewFilter: false,
                isDuplicateFilter: false,
                filterDisabled,
                filters: updatedFilters,
                filteredRows,
            });
            return true;
        } catch (error) {
            this.props.onSetAppStageError(error);
        }
        return false;
    };

    private handleCancelFilter = (): void => {
        this.setState({
            editFilterId: undefined,
            filterModalOpen: false,
            isNewFilter: false,
            isDuplicateFilter: false,
        });
    };

    private handleNewFilter = (): void => {
        this.setState({
            filterModalOpen: true,
            filterMenuOpen: false,
            isNewFilter: true,
            isDuplicateFilter: false,
        });
    };

    // This is called from first option in the filter drop-down menu, 'Filter off'
    private handleFilterOff = (): void => {
        this.toggleFilter(true);
    };

    private toggleFilter = (filterDisabled: boolean): void => {
        const activeFilterId = this.state.activeFilterId;

        const filteredRows = this.getFilteredRows(this.getActiveFilter(activeFilterId, this.state.filters), filterDisabled, this.state.viewSource);

        this.setState({
            filterModalOpen: false,
            filterMenuOpen: false,
            filterDisabled,
            filteredRows,
        });
    };

    private handleFilterSelect = (filterToUse: IFilterUI): void => {
        if (filterToUse.isValid) {
            const filteredRows = this.getFilteredRows(this.getActiveFilter(filterToUse.id, this.state.filters), false, this.state.viewSource);

            this.setState({
                activeFilterId: filterToUse.id,
                filterMenuOpen: false,
                filterDisabled: false,
                filteredRows,
            });

            // If filter is invalid, open filter definition modal so user can update it
        } else {
            this.handleFilterEdit(filterToUse);
        }
    };

    private handleFilterEdit = (filterToUse: IFilterUI): void => {
        this.setState({
            editFilterId: filterToUse.id,
            filterModalOpen: true,
            filterMenuOpen: false,
            isNewFilter: false,
            isDuplicateFilter: false,
        });
    };

    private handleFilterDuplicate = (filterToUse: IFilterUI): void => {
        this.setState({
            editFilterId: filterToUse.id,
            filterModalOpen: true,
            filterMenuOpen: false,
            isNewFilter: false,
            isDuplicateFilter: true,
        });
    };

    private handleApplyFilter = async (filter: IFilterUI): Promise<boolean> => {
        const filterInstance = new Filter(this.state.viewSource!.viewData.columns, filter, false);

        // Override name if empty before save
        if (filterInstance.name === '') {
            filterInstance.setName(this.props.languageElements.ADHOC_FILTER_DEFAULT_NAME);
        }

        const filterToApply = filterInstance.toFilterInterface();

        let saveFilterResponse: AxiosResponse<IFilter>;

        try {
            if (filterToApply.id) {
                saveFilterResponse = await viewClient.updateFilter(this.viewId, filterToApply);
            } else {
                saveFilterResponse = await viewClient.addFilter(this.viewId, filterToApply);
            }

            const newFilter = new Filter(this.state.viewSource!.viewData.columns, saveFilterResponse.data, false).toDisplayModel();

            const updatedFilters = this.state.filters ? this.state.filters.slice() : [];

            if (this.state.isDuplicateFilter || this.state.isNewFilter) {
                updatedFilters.push(newFilter);
            } else {
                const appliedFilterIndex = this.getFilterIndex(newFilter.id, updatedFilters);
                updatedFilters.splice(appliedFilterIndex, 1, newFilter);
            }

            updatedFilters.sort(dynamicSort('name'));

            const filteredRows = this.getFilteredRows(newFilter, false, this.state.viewSource);

            this.setState({
                activeFilterId: newFilter.id,
                filterDisabled: false,
                filterModalOpen: false,
                isNewFilter: false,
                isDuplicateFilter: false,
                editFilterId: undefined,
                filters: updatedFilters,
                filteredRows,
            });
            return true;
        } catch (error) {
            this.props.onSetAppStageError(error);
        }
        return false;
    };

    private setFilterModalOpen = (filterId?: string): void => {
        this.setState((state) => ({
            filterModalOpen: !state.filterModalOpen,
            filterMenuOpen: false,
            editFilterId: filterId,
        }));
    };

    private setGridInformation = (
        sheetColumnMap: Map<number, dv.Column>,
        reportColumnMap: Map<number, dv.Column>,
        allColumnOptionsMap: Map<number, Map<string, MultiSelectItem>>,
        picklistSymbolImageMap: Map<string, SymbolLookup | undefined>,
        rows?: dv.Row[]
    ): void => {
        const { gridRows, gridCellMap, columnCellMap } = createGridInformation(
            sheetColumnMap,
            reportColumnMap,
            picklistSymbolImageMap,
            rows,
            this.props.languageElements,
            this.props.userLocale
        );
        this.gridRows = gridRows;
        this.gridCellMap = gridCellMap;
        this.columnCellMap = columnCellMap;
    };

    private setFilterInformation = (
        allColumnOptionsMap: Map<number, Map<string, MultiSelectItem>>,
        columnCellMap: Map<number, Map<string, MultiSelectItem>>
    ): void => {
        // Create a map of filter options by consolidating unique options in the gridData with all column option values defined in view source
        this.allFilterItemsMap = createAllFilterItemsMap(columnCellMap, allColumnOptionsMap);
    };

    private getActiveFilter = (filterId: string | undefined, filters?: IFilterUI[]): IFilterUI | undefined => {
        return filterId && filters ? filters.find((filter) => filter.id === filterId) : undefined;
    };

    private getFilterIndex = (filterId: string | undefined, filters: IFilterUI[]): number => {
        return filterId ? filters.findIndex((filter) => filter.id === filterId) : -1;
    };

    private handleEscape = () => {
        // If filter menu is open, close it
        if (this.state.filterMenuOpen) {
            this.setState({
                filterMenuOpen: false,
            });
        }
    };

    /**
     *
     * Returns array of sorted filters and removes any invalid shared filters for non-admin users.
     * (none are removed if user is owner/admin)
     */
    private getDisplayFilters = (viewData: ViewData | undefined, filters: IFilter[], currentUserAccessLevel: AccessLevel): IFilterUI[] => {
        const fields = viewData ? viewData.columns : [];

        return filters
            .sort(dynamicSort('name'))
            .map((filter: IFilter) => new Filter(fields, filter, false).toDisplayModel())
            .filter((filter) => {
                return (
                    currentUserAccessLevel === AccessLevel.OWNER ||
                    currentUserAccessLevel === AccessLevel.ADMIN ||
                    filter.isValid ||
                    filter.ownerUserId != null
                );
            });
    };

    // Escape any double quotes in cell content by converting to 2x double quotes so that csv parser works as expected
    private sanitizeForParsing = (cellString: string): string => {
        return cellString.replace(/"/g, '""');
    };

    private getCellValue = (cell: Cell, columnMap: Map<number, Column>): string => {
        const column = columnMap.get(cell.columnId || cell.virtualColumnId!);
        let cellValue = '';

        if (column && column.type === ColumnType.CHECKBOX) {
            // For checkbox columns, return 'false' if cell is blank or contains boolean false
            cellValue = cell.value ? cell.value.toString() : this.props.languageElements.FALSE;
        }

        if (column && column.type === ColumnType.DATE && isDateObjectValue(cell.objectValue) && Date.parse(cell.objectValue.value)) {
            cellValue = formattedDateString(cell.objectValue.value, getDefaultDateFnsFormatForLocale());
        }

        if (cell.value != null) {
            cellValue = cell.value.toString();
        }

        if (cell.objectValue != null && isMultiContactObjectValue(cell.objectValue)) {
            cellValue = cell.objectValue.values.map((value) => value.email).join(', ');
        }

        if (cell.objectValue != null && isMultiPicklistObjectValue(cell.objectValue) && cell.displayValue) {
            cellValue = cell.displayValue;
        }

        return this.sanitizeForParsing(cellValue);
    };

    private initializeView() {
        this.isDetailsRowDirty = false;
        this.viewId = this.props.match.params.viewId;
        this.loadViewStats = {
            inProgress: true,
            start: Date.now(),
        };

        UserAnalyticsGlobalContext.addViewDataFromId(this.viewId);

        const wrapText = OptionRepository.getOption(this.viewId, UserViewSettings.WRAP_TEXT);
        const showFormats = OptionRepository.getOption(this.viewId, UserViewSettings.SHOW_FORMATS, true);

        this.setState({ ...this.getInitialState(), wrapText, showFormats });

        this.props.onSetAppStageActionInProgress(this.props.languageElements.SPINNER_LOADING_LABEL);
        this.props.resetDetailsPanelState();
        this.props.resetViewState();

        this.setSmartsheetUsers();
        this.props.fetchViewData(this.viewId, this.props.isPaginationFlagEnabled);
        this.props.fetchConfig(this.viewId);
    }
}

const mapState = (state: StoreState) => ({
    leftSidePanelOpened: AppSelectors.leftSidePanelOpenedSelector(state),
    inIframe: AppSelectors.iframeStatusSelector(state),
    isBulletinDisplayed: AppSelectors.isBulletinDisplayedSelector(state),
    isUserLicensed: AuthSelectors.userHasLicenseAccessSelector(state),
    userLocale: AuthSelectors.userLocaleSelector(state),
    viewData: ViewSelectors.viewDataSelector(state),
    viewConfig: ViewSelectors.viewConfigSelector(state),
    disableContactPickerOnDemand: AppSelectors.isDisabledContactPickerOnDemandSelector(state),
    isPaginationFlagEnabled: AppSelectors.paginationFeatureSelector(state),
    isAttachmentFeatureEnabled: AppSelectors.attachmentsFeatureSelector(state),
    selectedRowId: DetailsPanelSelectors.selectedRowIdSelector(state),
});

const mapDispatch = {
    toggleLeftSidePanel: AppActions.Actions.toggleLeftSidePanel,
    showModalDetailsPanel: DetailsPanelActions.Actions.showModalDetailsPanel,
    resetDetailsPanelState: DetailsPanelActions.Actions.resetDetailsPanelState,
    selectRow: DetailsPanelActions.Actions.selectRow,
    unselectRow: DetailsPanelActions.Actions.unselectRow,
    storeRecentView: HomeActions.Actions.storeRecentView,
    fetchImageUrls: ImageActions.Actions.fetchImageUrls,

    resetViewState: ViewActions.Actions.resetViewState,
    fetchViewData: ViewActions.Actions.fetchViewData,
    fetchConfig: ViewActions.Actions.fetchConfig,
};

const connector = connect(mapState, mapDispatch);
type PropsFromRedux = ConnectedProps<typeof connector>;
export default withLanguageElementsHOC(withRouter(withSetAppActionInProgress(withSetAppError(connector(ViewContainer)))));

const SpinnerWrapper = styled.div`
    padding: ${sizes.xSmall}px;
    margin-left: ${sizes.small}px;
    margin-top: 2px;
    display: flex;
    flex-direction: row;
    background-color: ${colors.neutralLight40};
    width: auto;
    align-items: center;
    height: 28px;
`;

const SpinnerLabel = styled.span`
    padding-left: ${sizes.small}px;
    line-height: 26px;
    text-align: center;
    color: ${colors.neutralDark30};
    font-weight: bold;
    font-size: 13px;
    font-family: Roboto, sans-serif;
`;
