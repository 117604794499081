/* eslint @typescript-eslint/no-misused-promises:"off" */
import * as React from 'react';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { FORBIDDEN_FILE_TYPES, MAX_FILE_SIZE_IN_BYTES } from '../../../../common/constants';
import { TimeZone } from '../../../../common/enums';
import { AutomationIds } from '../../../../common/enums/AutomationElements.enum';
import { Attachment as AttachmentInterface } from '../../../../common/interfaces';
import { getFileExtension } from '../../../../common/utils';
import { WithDataClientProps } from '../../../../components/hoc/WithDataClient';
import withSetAppError, { WithSetAppErrorProps } from '../../../../components/hoc/WithSetAppError';
import ModalWrapper from '../../../../components/Modal';
import Spinner, { Color, Size } from '../../../../components/Spinner';
import viewClient from '../../../../http-clients/View.client';
import { LanguageElementsProp, withLanguageElementsHOC } from '../../../../language-elements/withLanguageElementsHOC';
import { userLocaleSelector, userTimeZoneSelector } from '../../../Auth/Selectors';
import { currentRowSheetIdSelector } from '../../Selectors';
import { AttachmentUploaderMap } from './Attachment.interface';
import AttachmentItem from './AttachmentItem';

export interface OwnProps extends WithDataClientProps {
    rowId: number;
    viewId: string;
    attachments: AttachmentInterface[] | undefined;
    width: number;
    addAttachments: boolean;
    onAttach: () => void;
}

interface StateProps {
    userLocale: string;
    userTimeZone?: TimeZone;
    sheetId?: number;
}

interface State {
    attachments: AttachmentInterface[];
    isLoading: boolean;
    isSaving: boolean;
}

type Props = OwnProps & WithSetAppErrorProps & StateProps & LanguageElementsProp;

class AttachmentLegacy extends React.Component<Props, State> {
    public state: State;
    private attachInput: HTMLInputElement | null;
    private uploaderMap: AttachmentUploaderMap = {};

    public constructor(props: Props & WithSetAppErrorProps) {
        super(props);
        this.state = {
            attachments: props.attachments || [],
            isLoading: false,
            isSaving: false,
        };
    }

    public render(): JSX.Element {
        const addAttachments: JSX.Element | null = this.props.addAttachments ? (
            <div key={AutomationIds.ATTACHMENT_ADD_CONTAINER} className="attach-file-wrap">
                <div className="attach-file">
                    <button data-client-id={AutomationIds.ATTACHMENT_ATTACH_BUTTON} className="attach-label" onClick={this.handleInputClick}>
                        <div className="attach-label-inner">
                            <span className="item-icon icon-attach" />
                            <span>{this.props.languageElements.DETAIL_ATTACHMENTS_ATTACH_FILE}</span>
                        </div>
                    </button>
                    <input
                        type="file"
                        className="attach-input"
                        onChange={this.handleFileUpload}
                        ref={(input) => {
                            this.attachInput = input;
                        }}
                    />
                </div>
            </div>
        ) : null;

        const attachmentsList: JSX.Element | null = this.state.attachments.length ? (
            <div key={AutomationIds.ATTACHMENT_LIST_CONTAINER} className="attachments">
                {this.state.attachments.map((att, index) => (
                    <AttachmentItem
                        key={index}
                        name={att.name}
                        url={att.url}
                        createdBy={att.createdBy}
                        sizeInKb={att.sizeInKb!}
                        createdAt={att.createdAt!}
                        uploader={this.uploaderMap[att.id]}
                        mimeType={att.mimeType}
                        userLocale={this.props.userLocale}
                        userTimeZone={this.props.userTimeZone}
                        onClick={(url) => this.handleOpenAttachment(this.state.attachments[index].id, url)}
                    />
                ))}
            </div>
        ) : (
            <div key={AutomationIds.ATTACHMENT_EMPTY} className="attachments empty">
                {this.props.languageElements.DETAIL_ATTACHMENTS_EMPTY}
            </div>
        );
        return (
            <div
                key={AutomationIds.ATTACHMENT_WRAP}
                data-client-id={AutomationIds.ATTACHMENT_WRAP}
                data-testid={AutomationIds.ATTACHMENT_WRAP}
                className="attachments-wrap"
            >
                {!this.state.isSaving && !this.state.isLoading && [attachmentsList, addAttachments]}
                {this.state.isSaving && (
                    <ModalWrapper isModalOpen={true} onClose={() => {}} hideCloseButton={true} focusTrap={false}>
                        <Spinner label={this.props.languageElements.SPINNER_SAVING_LABEL} color={Color.BLUE} size={Size.MEDIUM} />
                    </ModalWrapper>
                )}
                {this.state.isLoading && <Spinner label={this.props.languageElements.SPINNER_LOADING_LABEL} color={Color.BLUE} size={Size.MEDIUM} />}
            </div>
        );
    }

    public componentDidUpdate(prevProps: Props): void {
        if (prevProps.rowId !== this.props.rowId) {
            this.setState({ attachments: this.props.attachments || [] });
        } else if (this.props.attachments !== prevProps.attachments) {
            this.setState({ attachments: this.props.attachments || [] });
        }
    }
    private handleInputClick = (): void => {
        if (this.attachInput) {
            this.attachInput.click();
        }
    };

    private handleFileUpload = (e: React.ChangeEvent<HTMLInputElement>): void => {
        if (e.target && e.target.files && e.target.files.length > 0) {
            const file: File = e.target.files[0];

            // Check file extension before upload
            const fileExtension = getFileExtension(file.name);

            if (FORBIDDEN_FILE_TYPES.has(fileExtension)) {
                this.props.onSetAppStageError(new Error(this.props.languageElements.INVALID_ATTACHMENT_TYPE));
            } else if (file.size > MAX_FILE_SIZE_IN_BYTES) {
                this.props.onSetAppStageError(new Error(this.props.languageElements.FILE_SIZE_EXCEEDS_LIMITS));
            } else if (file.size < 1) {
                this.props.onSetAppStageError(new Error(this.props.languageElements.FILE_REQUIRED));
            } else {
                // TODO: figure out implementation of progress indicator
                this.attachmentSend(file);
                e.target.value = '';
            }
        }
    };
    private handleOpenAttachment = async (attachmentId: number, url: string | undefined) => {
        if (!url) {
            this.setState({
                isLoading: true,
            });

            try {
                const response = await viewClient.getRowAttachment(this.props.viewId, this.props.rowId, attachmentId, this.props.sheetId);
                this.setState({
                    isLoading: false,
                });
                window.open(response.data.url, '_self');
            } catch (error) {
                this.setState(
                    {
                        isLoading: false,
                    },
                    () => this.props.onSetAppStageError(error)
                );
            }
        }
    };

    private attachmentSend = async (file: File) => {
        this.setState({
            isSaving: true,
        });
        try {
            await viewClient.addRowAttachment(this.props.viewId, this.props.rowId!, file, this.props.sheetId);
            this.setState(
                {
                    isSaving: false,
                },
                this.props.onAttach
            );
        } catch (error) {
            this.setState(
                {
                    isSaving: false,
                },
                () => this.props.onSetAppStageError(error)
            );
        }
    };
}

const mapState = createStructuredSelector({
    userLocale: userLocaleSelector,
    userTimeZone: userTimeZoneSelector,
    sheetId: currentRowSheetIdSelector,
});

export default withLanguageElementsHOC(withSetAppError(connect<StateProps>(mapState)(AttachmentLegacy)));
